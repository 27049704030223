import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { FavoritesProvider } from "./context/FavoritesContext";
import { CartProvider } from "./context/CartContext";
import {
  retrieveLaunchParams,
  expandViewport,
  init,
  miniApp,
  swipeBehavior,
} from "@telegram-apps/sdk";

const { initDataRaw } = retrieveLaunchParams();

init();

if (miniApp.mount.isAvailable()) {
  miniApp.mount();
}

if (swipeBehavior.mount.isAvailable()) {
  swipeBehavior.mount();
}

if (swipeBehavior.enableVertical.isAvailable()) {
  swipeBehavior.disableVertical();
}

function isTokenExpired(expirationTime) {
  return Date.now() > expirationTime;
}

function fetchNewToken() {
  return fetch(`${process.env.REACT_APP_API_URL}/authorize`, {
    method: "POST",
    headers: {
      Authorization: `tma ${initDataRaw}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Authorization failed");
    })
    .then((data) => {
      const jwtToken = data.token;
      const expirationTime = data.exp * 1000;

      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("tokenExpiration", expirationTime);

      return jwtToken;
    })
    .catch((error) => {
      console.error("Error fetching token:", error);
    });
}

function validateToken(jwtToken) {
  return fetch(`${process.env.REACT_APP_API_URL}/authorize/check`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
    .then((response) => {
      if (response.status === 401) {
        return null;
      }
      if (response.ok) {
        return response.json();
      }
      throw new Error("Unexpected response while validating token");
    })
    .then((data) => {
      if (data?.exp) {
        const expirationTime = Number(localStorage.getItem("tokenExpiration"));
        if (data.exp * 1000 !== expirationTime) {
          localStorage.setItem("tokenExpiration", data.exp * 1000);
        }
        return jwtToken;
      }
      return null;
    })
    .catch((error) => {
      console.error("Error validating token:", error);
      return null;
    });
}

async function initApp() {
  let jwtToken = localStorage.getItem("jwtToken");
  const expirationTime = Number(localStorage.getItem("tokenExpiration"));

  if (!jwtToken || isTokenExpired(expirationTime)) {
    jwtToken = await fetchNewToken();
  } else {
    const validatedToken = await validateToken(jwtToken);
    if (!validatedToken) {
      jwtToken = await fetchNewToken();
    }
  }

  if (expandViewport.isAvailable()) {
    expandViewport();
  }

  ReactDOM.createRoot(document.getElementById("root")).render(
    <CartProvider>
      <FavoritesProvider>
        <App />
      </FavoritesProvider>
    </CartProvider>
  );
}

initApp();
