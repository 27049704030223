import React, { useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Search.css";
import { Link } from "react-router-dom";

function Search({ isOpen, closeSearch }) {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(null);

  const handleSearch = useCallback(
    async (e) => {
      const value = e.target.value;
      setQuery(value);

      if (value.trim() === "") {
        setResults([]);
        return;
      }

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/products/search?query=${value}`
          );
          if (!response.ok) {
            throw new Error("Ошибка при загрузке данных");
          }
          const data = await response.json();
          setResults(data);
        } catch (err) {
          setError("Ошибка при загрузке данных");
        } finally {
          setLoading(false);
        }
      }, 300);

      setTimer(newTimer);
    },
    [timer]
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="search-modal"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <motion.div
            className="search-container"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <input
              type="text"
              placeholder="Искать товары..."
              value={query}
              onChange={handleSearch}
              className="search-input"
            />
            <button className="search-close" onClick={closeSearch}>
              ✕
            </button>
          </motion.div>

          <div className="search-results">
            {error && <p className="error">{error}</p>}
            {!loading && results.length === 0 && query.trim() && (
              <p className="notfound">Ничего не найдено</p>
            )}
            <ul className="results-list">
              {results.map((item) => (
                <li key={item.id} className="result-item">
                  <Link
                    to={`/product/${item.id}`}
                    onClick={closeSearch}
                    className="result-link"
                  >
                    <img
                      src={process.env.REACT_APP_API_URL + item.coverSrc}
                      alt={item.title}
                      className="result-image"
                    />
                    <div className="result-info">
                      <h3 className="result-title">{item.title}</h3>
                      <p
                        className="result-desc"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      <div className="result-prices">
                        <span className="price">{item.price} ₽</span>
                        {item.old_price > item.price && (
                          <span className="old-price">{item.old_price} ₽</span>
                        )}
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Search;
