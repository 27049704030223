import React, { useState } from "react";
import { HiBars2 } from "react-icons/hi2";
import { GiShoppingBag } from "react-icons/gi";
import { PiHeartThin } from "react-icons/pi";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Cart from "./Cart";
import Favorites from "./Favorites";
import SideMenu from "./SideMenu";
import Search from "./Search";
import { useCart } from "../context/CartContext";
import "./Header.css";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isFavoritesOpen, setIsFavoritesOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { cartCount, isCartLoaded, updateCartCount } = useCart();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCart = () => setIsCartOpen(!isCartOpen);
  const toggleFavorites = () => setIsFavoritesOpen(!isFavoritesOpen);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const closeCart = () => {
    setIsCartOpen(false);
    updateCartCount();
  };

  return (
    <>
      <header className="header">
        <div className="header-left">
          <Link to="/">
            <h1 className="logo">Biksbit</h1>
          </Link>
        </div>
        <div className="header-right">
          <button className="search-button" onClick={toggleSearch}>
            <IoSearchOutline size={22} color="#000" />
          </button>
          <button className="favorite-button" onClick={toggleFavorites}>
            <PiHeartThin size={24} color="#000" />
          </button>
          <button className="cart-button" onClick={toggleCart}>
            <GiShoppingBag size={18} color="#000" />
            {isCartLoaded && cartCount !== undefined && cartCount !== null ? (
              <span className="cart-count">{cartCount}</span>
            ) : null}
          </button>
          <button className="menu-button" onClick={toggleMenu}>
            <HiBars2 size={24} color="#000" />
          </button>
        </div>
      </header>
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
      {isFavoritesOpen && (
        <div className="overlay" onClick={toggleFavorites}></div>
      )}
      {isSearchOpen && <div className="overlay" onClick={toggleSearch}></div>}
      <SideMenu isOpen={isMenuOpen} closeMenu={toggleMenu} />
      <Cart isOpen={isCartOpen} closeCart={closeCart} />
      <Favorites isOpen={isFavoritesOpen} closeFavorites={toggleFavorites} />
      <Search isOpen={isSearchOpen} closeSearch={toggleSearch} />
    </>
  );
}

export default Header;
