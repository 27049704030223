import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import ProductList from "./pages/ProductList";
import ProductDetails from "./pages/ProductDetails";
import ScrollToTopButton from "./components/ScrollToTopButton";
// import Marque from "./components/Marque";
import "./App.css";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <Router>
      <div className="container">
        <Header toggleMenu={toggleMenu} />
        {/* <ConditionalMarque /> */}
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route
            path="/products/:categoryId"
            element={<ProductList />}
          />
          <Route
            path="/products/:categoryId/:typeId"
            element={<ProductList />}
          />
        </Routes>
        <ScrollToTopButton />
      </div>
    </Router>
  );
}

// function ConditionalMarque() {
//   const location = useLocation();

//   const isProductPage = location.pathname.startsWith("/product/");

//   if (isProductPage) {
//     return null;
//   }

//   return <Marque />;
// }

export default App;
