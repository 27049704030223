import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./ProductDetails.css";
import { useCart } from "../context/CartContext";
import { useFavorites } from "../context/FavoritesContext";
import { TiHeartOutline, TiHeartFullOutline } from "react-icons/ti";
import { motion } from "framer-motion";
import { SiVk, SiInstagram } from "react-icons/si";
import Faq from "react-faq-component";

function ProductDetails() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { updateCartCount } = useCart();
  const { favorites, toggleFavorite } = useFavorites();

  useEffect(() => {
    if (productId) {
      fetch(`${process.env.REACT_APP_API_URL}/products/${productId}`)
        .then((response) => response.json())
        .then((data) => {
          setProduct(data);
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    }
  }, [productId]);

  const handleAddToCart = async () => {
    try {
      setIsAddingToCart(true);
      const jwtToken = localStorage.getItem("jwtToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/${productId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.ok) {
        updateCartCount();
      } else {
        console.error(
          "Ошибка добавления товара в корзину:",
          await response.json()
        );
      }
    } catch (error) {
      console.error("Ошибка запроса:", error);
    } finally {
      setIsAddingToCart(false);
    }
  };

  if (!product || !product.photos) {
    return <div>Загрузка...</div>;
  }

  const data = {
    rows: [
      {
        title: "РЕКОМЕНДАЦИИ ПО УХОДУ",
        content: `По возможности оформляйте работу в раму сразу, или положите вышитую работу в индивидуальный пакет, чтобы предотвратить попадание пыли\nСтирать картины из бисера и хрусталя не желательно, однако если такая необходимость возникла, следует делать это очень осторожно: стирать только вручную, без применения моющих средств, и ни в коем случае не выкручивать - лучше разложить на прямой поверхности и оставить так до полного высыхания.`,
      },
      {
        title: "ДОСТАВКА",
        content: `Из-за ручного изготовления и крайне сложного производства срок изготовления работ действительно длительный процесс. В совокупности с большим спросом всё\n\n*Срок производства в среднем от 5 до 45 рабочих дней, не учитывая последующую доставку заказа`,
      },
    ],
  };

  const styles = {
    titleTextColor: "black",
    rowTitleColor: "black",
  };

  const config = {
    animate: true,
    expandIcon: "+",
    collapseIcon: "-",
  };

  return (
    <div className="product-details">
      <div className="gallery">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          lazy={{ loadPrevNext: true }}
          loop={true}
          modules={[Navigation, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          className="main-gallery"
        >
          {product.photos.map((src, index) => (
            <SwiperSlide key={index}>
              <img
                src={process.env.REACT_APP_API_URL + src}
                alt={`photo${index + 1}`}
                className="gallery-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          spaceBetween={5}
          slidesPerView={8}
          onSwiper={setThumbsSwiper}
          watchSlidesProgress
          modules={[Thumbs]}
          className="thumb-gallery"
        >
          {product.photos.map((src, index) => (
            <SwiperSlide key={index}>
              <img
                src={process.env.REACT_APP_API_URL + src}
                alt={`Thumbnail ${index + 1}`}
                className="thumb-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="product-info">
        <h1 className="product-details-title">{product.title}</h1>
        <div className="product-prices">
          <span className="price">{product.price} ₽</span>
          {product.old_price > 0 && (
            <span className="old-price">{product.old_price} ₽</span>
          )}
        </div>
        <div className="product-actions">
          <button
            className="add-to-cart"
            onClick={handleAddToCart}
            disabled={isAddingToCart}
          >
            Добавить в корзину
          </button>
          <motion.div
            className={`favorite-icon-details ${
              favorites.has(product.id) ? "favorited" : ""
            }`}
            onClick={() => toggleFavorite(product.id)}
            whileTap={{ scale: 0.8 }}
            transition={{ type: "spring", stiffness: 400, damping: 40 }}
          >
            {favorites.has(product.id) ? (
              <TiHeartFullOutline size={24} color="#008ab8" />
            ) : (
              <TiHeartOutline size={24} color="#000" />
            )}
          </motion.div>
        </div>
        <div
          className="product-description"
          dangerouslySetInnerHTML={{ __html: product.description }}
        ></div>
      </div>
      <Faq data={data} styles={styles} config={config} />
      <footer className="footer">
        <div className="footer-left">
          <img src="/logo.svg" alt="Logo" className="footer-logo" />
          <div className="social-icons-product">
            <a href="https://vk.com" target="_blank" rel="noopener noreferrer">
              <SiVk size={22} color="#000" />
            </a>
            <a
              href="https://www.instagram.com/biksbit.store?igsh=cDByNjY1d3Z6eXky"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiInstagram size={22} color="#000" />
            </a>
          </div>
        </div>
        <div className="footer-right">
          <ul>
            <li>
              <a href="/terms">Пользовательское соглашение</a>
            </li>
            <li>
              <a href="/privacy">Политика конфиденциальности</a>
            </li>
            <li>
              <a href="https://t.me/biksbitstore">Контакты</a>
            </li>
            <li>
              <p>2024 All rights reserved</p>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default ProductDetails;
