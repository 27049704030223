import React, { createContext, useContext, useState, useEffect } from "react";

const FavoritesContext = createContext();

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(new Set());
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const fetchFavorites = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) return;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/favorites`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFavorites(new Set(data.map((fav) => fav.id)));
        setFavoriteProducts(data);
      }
    } catch (error) {
      console.error("Ошибка загрузки избранных:", error);
    }
  };

  const toggleFavorite = async (productId) => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) return;

      const updatedFavorites = new Set(favorites);

      if (updatedFavorites.has(productId)) {
        updatedFavorites.delete(productId);
        await fetch(
          `${process.env.REACT_APP_API_URL}/favorites/remove/${productId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      } else {
        updatedFavorites.add(productId);
        await fetch(
          `${process.env.REACT_APP_API_URL}/favorites/add/${productId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
      }

      setFavorites(updatedFavorites);
      fetchFavorites();
    } catch (error) {
      console.error("Ошибка изменения избранного:", error);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        favoriteProducts,
        toggleFavorite,
        reloadFavorites: fetchFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
