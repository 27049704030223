import React, { useEffect, useRef } from "react";
import useSWRInfinite from "swr/infinite";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { TiHeartOutline, TiHeartFullOutline } from "react-icons/ti";
import { useFavorites } from "../context/FavoritesContext";
import SkeletonCard from "../components/SkeletonCard";
import "./ProductList.css";

const fetcher = (url) => fetch(url).then((res) => res.json());

function ProductList() {
  const { favorites, toggleFavorite } = useFavorites();
  const { categoryId, typeId } = useParams();
  const loadMoreRef = useRef(null);

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.products.length) return null;
    return `${process.env.REACT_APP_API_URL}/products?page=${pageIndex + 1}${
      categoryId ? `&category_id=${categoryId}` : ""
    }${typeId ? `&type_id=${typeId}` : ""}`;
  };

  const { data, error, size, setSize, isValidating } = useSWRInfinite(
    getKey,
    fetcher,
    {
      revalidateFirstPage: false,
      revalidateOnFocus: false,
    }
  );

  const products = data ? data.flatMap((page) => page.products) : [];
  const lastPage = data?.[data.length - 1]?.last_page || 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isValidating && size < lastPage) {
          setSize((prevSize) => prevSize + 1);
        }
      },
      { rootMargin: "100px" }
    );

    const currentRef = loadMoreRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isValidating, size, lastPage, setSize]);

  if (error) return <div>Ошибка загрузки данных</div>;

  return (
    <div className="product-list">
      {products.length
        ? products.map((product) => (
            <div className="product-card" key={product.id}>
              <Link to={`/product/${product.id}`} className="product-link">
                <img
                  src={process.env.REACT_APP_API_URL + product.coverSrc}
                  alt={product.title}
                  className="product-image"
                />
                <h3 className="product-title">{product.title}</h3>
                <div className="price-block">
                  <p className="price">{product.price} ₽</p>
                  {product.old_price > 0 && (
                    <span className="old-price">{product.old_price} ₽</span>
                  )}
                </div>
              </Link>
              <motion.div
                className={`favorite-icon ${
                  favorites.has(product.id) ? "favorited" : ""
                }`}
                onClick={() => toggleFavorite(product.id)}
                whileTap={{ scale: 0.8 }}
                transition={{ type: "spring", stiffness: 400, damping: 20 }}
              >
                {favorites.has(product.id) ? (
                  <TiHeartFullOutline size={24} color="#008ab8" />
                ) : (
                  <TiHeartOutline size={24} color="#000" />
                )}
              </motion.div>
            </div>
          ))
        : Array(6)
            .fill(0)
            .map((_, index) => <SkeletonCard key={index} />)}

      {isValidating && (
        <>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <SkeletonCard key={index} />
            ))}
        </>
      )}

      {}
      <div ref={loadMoreRef} className="load-more-trigger" />
    </div>
  );
}

export default ProductList;
