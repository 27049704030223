import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  const updateCartCount = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");

      if (!jwtToken) {
        console.error("JWT токен отсутствует");
        setIsCartLoaded(true);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/count`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка при загрузке количества товаров в корзине");
      }

      const data = await response.json();
      setCartCount(data.total_count || 0);
    } catch (error) {
      console.error("Ошибка загрузки количества товаров в корзине:", error);
    } finally {
      setIsCartLoaded(true);
    }
  };

  useEffect(() => {
    updateCartCount();
  }, []);

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount, isCartLoaded }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
