import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CiCircleRemove } from "react-icons/ci";
import { useFavorites } from "../context/FavoritesContext";
import "./Favorites.css";

function Favorites({ isOpen, closeFavorites }) {
  const { favoriteProducts, reloadFavorites, toggleFavorite } = useFavorites();
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (isOpen && !hasFetched) {
      reloadFavorites();
      setHasFetched(true);
    }
    if (!isOpen) {
      setHasFetched(false);
    }
  }, [isOpen, reloadFavorites, hasFetched]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="favorites-menu"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <div className="favorites-header">
            <h2>Избранное</h2>
            <button className="close-favorites" onClick={closeFavorites}>
              ×
            </button>
          </div>
          <div className="favorites-list">
            {favoriteProducts.length > 0 ? (
              favoriteProducts.map((product) => (
                <div key={product.id} className="favorite-item">
                  <img
                    src={process.env.REACT_APP_API_URL + product.coverSrc}
                    alt={product.title}
                    className="favorite-image"
                  />
                  <div className="favorite-details">
                    <h3>{product.title}</h3>
                    <div
                      className="favorite-desc"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    ></div>
                    <p className="favorite-price">{product.price} ₽</p>
                    <div className="remove-icon">
                      <CiCircleRemove
                        size={26}
                        color="#424242"
                        opacity={0.5}
                        onClick={() => toggleFavorite(product.id)}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Вы ещё ничего не добавили в избранное.</p>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Favorites;
