import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CiCircleRemove, CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { miniApp, openTelegramLink } from "@telegram-apps/sdk";
import "./Cart.css";

function Cart({ isOpen, closeCart }) {
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [isCheckingOut, setIsCheckingOut] = useState(false);

  const fetchCart = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");

      const response = await fetch(`${process.env.REACT_APP_API_URL}/cart`, {
        method: "GET",
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      const data = await response.json();
      setCartItems(data.items || []);
      setSubtotal(data.subtotal || 0);
    } catch (error) {
      console.error("Ошибка загрузки корзины:", error);
    }
  };

  const updateItemQuantity = async (itemId, newCount) => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/${itemId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ count: newCount }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCartItems(data.items);
        setSubtotal(data.subtotal);
      }
    } catch (error) {
      console.error("Ошибка обновления количества товара:", error);
    }
  };

  const removeItem = async (itemId) => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/${itemId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      if (response.ok) {
        const itemToRemove = cartItems.find((item) => item.id === itemId);
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.id !== itemId)
        );
        setSubtotal(
          (prevSubtotal) =>
            prevSubtotal - itemToRemove.count * itemToRemove.price
        );
      }
    } catch (error) {
      console.error("Ошибка удаления товара:", error);
    }
  };

  const handleCheckout = async () => {
    if (isCheckingOut) return;

    setIsCheckingOut(true);

    try {
      const jwtToken = localStorage.getItem("jwtToken");

      const preparedItems = cartItems.map((item) => ({
        id: item.id,
        count: item.count,
      }));

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/checkout`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ items: preparedItems }),
        }
      );

      if (response.ok) {
        setCartItems([]);
        setSubtotal(0);
        const data = await response.json();

        if (openTelegramLink.isAvailable()) {
          openTelegramLink(
            `https://t.me/${process.env.REACT_APP_ADMIN_USERNAME}?text=Здравствуйте! Пишу вам по заказу под номером ${data.order_id}, на сумму ${data.total_cost} ₽, уточнить детали доставки.`
          );
        }

        miniApp.close();
      } else {
        const errorData = await response.json();
        console.error("Ошибка оформления заказа:", errorData);
        alert("Ошибка оформления заказа: " + errorData.message);
      }
    } catch (error) {
      console.error("Ошибка оформления заказа:", error);
      alert("Произошла ошибка при оформлении заказа.");
    } finally {
      setIsCheckingOut(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCart();
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="cart-menu"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{ type: "spring", stiffness: 400, damping: 30 }}
        >
          <div className="cart-header">
            <h2>Корзина</h2>
            <button className="close-cart" onClick={closeCart}>
              ×
            </button>
          </div>
          <div className="cart-list">
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <div key={item.id} className="cart-item">
                  <img
                    src={process.env.REACT_APP_API_URL + item.coverSrc}
                    alt={item.title}
                    className="cart-item-image"
                  />
                  <div className="cart-item-details">
                    <h3>{item.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></p>
                    <div className="cart-item-quantity">
                      <CiCircleMinus
                        className="minus"
                        size={22}
                        opacity={0.5}
                        onClick={() =>
                          item.count > 1
                            ? updateItemQuantity(item.id, item.count - 1)
                            : null
                        }
                      />
                      <span>{item.count}</span>
                      <CiCirclePlus
                        className="plus"
                        size={22}
                        opacity={0.5}
                        onClick={() =>
                          updateItemQuantity(item.id, item.count + 1)
                        }
                      />
                      <span className="item-price">{item.price} ₽</span>
                    </div>
                    <div className="remove-icon">
                      <CiCircleRemove
                        size={26}
                        color="#424242"
                        opacity={0.5}
                        onClick={() => removeItem(item.id)}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Ваша корзина пуста.</p>
            )}
          </div>

          {cartItems.length > 0 && (
            <>
              <div className="subtotal">
                <p>Итого: {subtotal} ₽</p>
              </div>
              <div className="cart-footer">
                <button
                  className="checkout-button"
                  onClick={handleCheckout}
                  disabled={isCheckingOut}
                >
                  {isCheckingOut ? "Оформляется..." : "Оформить заказ"}
                </button>
              </div>
            </>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Cart;
