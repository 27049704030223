import React from "react";
import "./SkeletonCard.css";
import { motion } from "framer-motion";

function SkeletonCard() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30, scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      transition={{
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1],
      }}
    >
      <div className="skeleton-card">
        <div className="skeleton-image"></div>
        <div className="skeleton-text skeleton-title"></div>
      </div>
    </motion.div>
  );
}

export default SkeletonCard;
